// Import des variables et mixins globaux
@import '../styles/variables';
@import '../styles/mixins';

// Styles globaux pour le corps du document
body {
    color: $body-color-dark; // Couleur du texte définie dans les variables
    margin: 0;
    padding: 0;
    box-sizing: border-box; // Utilisation de border-box pour le modèle de box
    font-family: $font-stack; // Police de caractère définie dans les variables
    -webkit-font-smoothing: antialiased; // Lissage des polices pour WebKit
    -moz-osx-font-smoothing: grayscale; // Lissage des polices pour macOS
}
// Styles pour les éléments généraux de l'application
.container {
    max-width: 1300px; // Largeur maximale du conteneur
    margin: 0 auto; // Centrer le conteneur horizontalement
    padding: 0 15px; // Espacement intérieur du conteneur

    @media screen and (max-width: 991px) {
        padding: 0 10px; // Réduire l'espacement intérieur pour les écrans plus petits
    }
}
// Styles spécifiques pour les liens
a {
    color: $react-color; // Couleur des liens principale définie dans les variables
    text-decoration: none; // Pas de soulignement par défaut pour les liens

    &:hover {
        text-decoration: underline; // Soulignement au survol des liens
    }
}
