@import '../../styles/variables';
@import '../../styles/mixins';

// Styles généraux pour la page Contact
.contact {
    background: $quartary-color;
    position: relative; /* positionnée relativement pour que l'animation fonctionne correctement */
    min-height: 80vh;

    // MEDIA QUERIES - MOBILES
    @media screen and (max-width: 767px) {
        min-height: 90vh;
    }

    .contact-content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        // Styles pour chaque colonne de contenu
        .column {
            width: calc(50% - 30px);

            // MEDIA QUERIES - TABLETTES
            @media screen and (max-width: 991px) {
                width: initial; /* Réinitialiser la largeur sur tablettes */
            }

            .text {
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 10px;
            }

            p {
                text-align: justify;
            }
            // Styles pour les icônes d'information
            .icons {
                margin: 10px 0;
                .row > * {
                    width: 0% !important;
                }
                .row {
                    display: flex;
                    height: 65px;
                    align-items: center;

                    i {
                        font-size: 25px;
                        color: $react-color;
                    }
                    // Styles pour les informations de contact
                    .info {
                        margin-left: 30px;

                        .head {
                            font-weight: 500;
                        }

                        .sub-title {
                            color: $react-color;
                        }
                    }
                }
            }
        }
    }
    // Section de droite pour le formulaire de contact
    .right {
        form {
            .field {
                height: 45px;
                width: 100%;
                margin-bottom: 15px;

                input,
                textarea {
                    height: 100%;
                    width: 100%;
                    border: 1px solid lightgrey;
                    border-radius: 6px;
                    outline: none;
                    padding: 0 15px;
                    transition: border-color 0.3s ease;

                    &:focus {
                        border-color: #b3b3b3;
                    }
                }
                // Styles spécifiques pour la zone de texte
                &.textarea {
                    height: 80px;

                    textarea {
                        padding-top: 10px;
                        resize: none;
                    }
                }
            }
            // Styles pour les champs de formulaire
            .fields {
                display: flex;

                .name {
                    margin-right: 10px;
                }
            }
            // Styles pour la zone du bouton d'envoi
            .button-area {
                display: flex;
                align-items: center;

                button {
                    background-color: $react-color;
                    display: block;
                    width: 160px;
                    height: 45px;
                    outline: none;
                    font-size: 18px;
                    font-weight: 500;
                    border-radius: 6px;
                    cursor: pointer;
                    border: 2px solid $react-color;
                    transition:
                        background 0.3s ease-in-out,
                        color 0.3s ease-in-out;

                    &:hover {
                        background: $tertiary-color;
                        color: #fff;
                    }
                }

                // MEDIA QUERIES - MOBILES
                @media screen and (max-width: 767px) {
                    justify-content: center; /* Centre le bouton sur mobile */
                    width: 100%; /* Prendre toute la largeur disponible */

                    button {
                        width: auto; /* Ajuster la largeur automatiquement */
                    }
                }
            }
        }
    }
}
