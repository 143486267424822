// Mixin pour les polices
@mixin font-stack($stack) {
  font-family: $stack;
}

// Mixin pour les boîtes centrées
@mixin center-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

// Mixin pour les colonnes
@mixin column-styles {
  width: calc(50% - 30px); // Largeur calculée pour deux colonnes avec espacement
  margin-bottom: 20px; // Ajouté pour l'espacement vertical entre les colonnes
}

// Mixin pour l'animation du point vers la gauche
@mixin move-dot-left {
  @keyframes move-dot-left {
    0% {
      left: 50%; // Position initiale
    }
    100% {
      left: 0; // Position finale (vers la gauche)
    }
  }
  animation: move-dot-left 3s linear infinite; // Animation du point vers la gauche
}

// Mixin pour l'animation du point vers la droite
@mixin move-dot-right {
  @keyframes move-dot-right {
    0% {
      left: 50%; // Position initiale
    }
    100% {
      left: 100%; // Position finale (vers la droite)
    }
  }
  animation: move-dot-right 3s linear infinite; // Animation du point vers la droite
}

// Mixin pour l'animation de la ligne vers la gauche
@mixin draw-line-left {
  @keyframes draw-line-left {
    0% {
      width: 0; // Largeur initiale (invisible)
      left: 50%; // Position initiale
    }
    100% {
      width: 50%; // Largeur finale (vers la gauche)
      left: 0; // Position finale
    }
  }
  animation: draw-line-left 3s linear infinite; // Animation de la ligne vers la gauche
}

// Mixin pour l'animation de la ligne vers la droite
@mixin draw-line-right {
  @keyframes draw-line-right {
    0% {
      width: 0; // Largeur initiale (invisible)
      left: 50%; // Position initiale
    }
    100% {
      width: 50%; // Largeur finale (vers la droite)
      left: 50%; // Position finale
    }
  }
  animation: draw-line-right 3s linear infinite; // Animation de la ligne vers la droite
}
