// Couleurs
$react-color: #3eb9d8; // Couleur principale (React color)
$react-color-darker: rgba(104, 177, 195, 0.436); // Variante plus sombre de la couleur principale
$red-color: #da1212d1; // Couleur rouge
$footer-color: #fff; // Couleur du texte du footer

// Couleurs Background dark
$project-content-dark: #000000c6; // Couleur de fond pour le contenu de projet (foncé)
$primary-color: #2b2e3069; // Couleur primaire (foncé)
$secondary-color: #e8e4e4; // Couleur secondaire (claire)
$tertiary-color: #25272a; // Couleur tertiaire (foncé)
$quartary-color: #101113; // Couleur quaternaire (foncé)
$quinary-color: #1f2124; // Couleur quinaire (foncé)

// Couleurs Font Dark
$body-color-dark: #fff; // Couleur du texte principal (foncé)
$nav-color-dark: black; // Couleur du texte de navigation (foncé)
$navsticky-color-dark: #fff; // Couleur du texte de navigation (collé, foncé)
$titles-color-dark: #fff; // Couleur des titres (foncé)
$home-color-dark: black; // Couleur du texte d'accueil (foncé)

// Couleurs Font Light
$body-color-light: black; // Couleur du texte principal (clair)
$nav-color-light: #fff; // Couleur du texte de navigation (clair)
$navsticky-color-light: black; // Couleur du texte de navigation (collé, clair)
$titles-color-light: black; // Couleur des titres (clair)
$home-color-light: #fff; // Couleur du texte d'accueil (clair)

// Polices
$font-stack: 'Raleway', 'Permanent Marker', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; // Pile de polices principales
$code-font-stack: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; // Pile de polices pour le code

// Espacements
$padding-base: 16px; // Espacement de base
$margin-base: 16px; // Marge de base

// MEDIA QUERIES
$mobile-max-width: 767px; // Largeur maximale pour les appareils mobiles
$tablet-max-width: 991px; // Largeur maximale pour les tablettes
