@import '../../styles/variables';
@import '../../styles/mixins';

// Styles généraux pour la section News
.news {
    background-color: $quartary-color; /* Couleur de fond */
    position: relative; /* Position relative pour l'animation */
    border-bottom: 3px solid $react-color-darker; /* Bordure inférieure */
    min-height: 80vh; /* Hauteur minimale */

    // MEDIA QUERIES - TABLETTES
    @media screen and (max-width: 991px) {
        min-height: 50vh; /* Ajustement de la hauteur pour les tablettes */
    }
}
// Titre de niveau 3
h3 {
    display: flex; /* Utilisation de flexbox */
    justify-content: center; /* Centrage horizontal */
    text-align: center !important; /* Centrage du texte */
}
// Titre de niveau 4
h4 {
    display: flex; /* Utilisation de flexbox */
    justify-content: center; /* Centrage horizontal */
    font-family: 'Permanent Marker'; /* Utilisation d'une police spécifique */
    font-size: xx-large; /* Taille de police très grande */
}
