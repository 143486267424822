@import '../../styles/variables';
@import '../../styles/mixins';

// Styles généraux pour la page About
.about {
    background: $quartary-color;
    position: relative; /* positionnée relativement pour que l'animation fonctionne correctement */
    border-bottom: 3px solid $react-color-darker; /* bordure inférieure */
    min-height: 80vh;

    // MEDIA QUERIES - MOBILES
    @media screen and (max-width: 767px) {
        min-height: 100vh;
    }

    .about-content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        // Styles pour la colonne de gauche (image)
        .left {
            flex: 0 1 45%;
            margin-right: 30px;

            img {
                height: 400px;
                width: 400px;
                object-fit: cover;
                border-radius: 6px;

                // MEDIA QUERIES - TABLETTES
                @media screen and (max-width: 991px) {
                    height: 300px;
                    width: 100%;
                }
                // MEDIA QUERIES - MOBILES
                @media screen and (max-width: 767px) {
                    height: auto;
                    width: 80%;
                }
            }
        }
        // Styles pour la colonne de droite (texte)
        .right {
            flex: 0 1 50%;

            .text {
                font-size: 25px;
                font-weight: 600;
                margin-bottom: 10px;
            }

            p {
                text-align: justify;

                // MEDIA QUERIES - MOBILES
                @media screen and (max-width: 767px) {
                    width: 100%; /* Prendre toute la largeur de l'écran */
                    margin: 0; /* Annule les marges */
                }
            }

            a {
                display: inline-block;
                background: $react-color;
                color: $quinary-color;
                font-size: 20px;
                font-weight: 500;
                padding: 8px 18px;
                margin-top: 20px;
                border-radius: 6px;
                border: 2px solid $react-color;
                transition:
                    background 0.3s ease-in-out,
                    color 0.3s ease-in-out;

                &:hover {
                    background: transparent;
                    color: #fff;
                }
            }
        }

        // MEDIA QUERIES - TABLETTES
        @media screen and (max-width: 991px) {
            flex-direction: column;

            .left {
                flex: 0 1 100%;
                margin-right: 0;
                margin-bottom: 20px;
                order: 1;
                display: flex;
                justify-content: center; /* Centre l'image horizontalement */
            }

            .right {
                flex: 0 1 100%;
                margin-right: 0;
                margin-bottom: 20px;
                order: 2;
                text-align: center; /* Centre le contenu de la colonne droite */

                a {
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }

        // MEDIA QUERIES - MOBILES
        @media screen and (max-width: 767px) {
            flex-direction: column;

            .left,
            .right {
                flex: 0 1 100%;
                margin-right: 0;
                margin-bottom: 20px;
            }

            .left {
                order: 1;
                display: flex;
                justify-content: center; /* Centre l'image horizontalement */
            }

            .right {
                order: 2;
                text-align: center; /* Centre le contenu de la colonne droite */

                a {
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }
}
// Styles pour les éléments de texte spécifiques (h3 et p)
h3 {
    margin-bottom: 1em;
    font-size: 1.5em;

    // MEDIA QUERIES - TABLETTES
    @media screen and (max-width: 991px) {
        text-align: initial;
        font-size: 1.3em;
    }
}

p {
    margin-bottom: 1em;
    font-size: 1.2em;

    // MEDIA QUERIES - TABLETTES
    @media screen and (max-width: 991px) {
        font-size: 1.1em;
    }
    // MEDIA QUERIES - MOBILES
    @media screen and (max-width: 767px) {
        font-size: 0.95em;
    }
}
