@import '../../styles/variables';
@import '../../styles/mixins';

// Styles pour la section des compétences
.skills {
    background-color: $tertiary-color; /* Couleur de fond */
    position: relative; /* Position relative pour les éléments positionnés */
    border-bottom: 3px solid $react-color-darker; /* Bordure inférieure */
    min-height: 80vh; /* Hauteur minimale de 80% de la hauteur de la vue */

    // MEDIA QUERIES - TABLETTES
    @media screen and (max-width: 991px) {
        // Aucun style spécifique pour les tablettes dans cette section
    }
    // MEDIA QUERIES - MOBILES
    @media screen and (max-width: 767px) {
        min-height: 80vh; /* Hauteur minimale ajustée pour les mobiles */
    }

    .skills-content {
        display: flex; /* Utilisation de flexbox */
        flex-wrap: wrap; /* Permettre le retour à la ligne */
        justify-content: space-around; /* Répartition équitable avec espacement autour */
        padding: 0 0.1rem;
        gap: 0.5rem 0; /* Espacement entre les éléments (vertical et horizontal) */
    }

    .skill-item {
        width: calc(23% - 1rem); /* Largeur calculée pour chaque élément */
        text-align: center; /* Centrage du texte */
        margin-bottom: 1rem; /* Marge inférieure */
        z-index: 5; /* Position z */

        // MEDIA QUERIES - TABLETTES
        @media screen and (max-width: 991px) {
            width: calc(
                25% - 1rem
            ); /* Ajustement de la largeur pour les tablettes */
        }
        // MOBILES
        @media screen and (max-width: 767px) {
            width: calc(
                30% - 1rem
            ); /* Ajustement de la largeur pour les mobiles */
        }

        figure {
            position: relative; /* Position relative pour les éléments positionnés */
            flex-direction: column; /* Affichage en colonne pour les enfants flexibles */
            display: flex; /* Utilisation de flexbox */
            justify-content: center; /* Centrage horizontal */
            align-items: center; /* Centrage vertical */
            transition: 0.5s; /* Transition générale */
            z-index: 1; /* Position z par défaut */

            &:hover {
                transform: scale(115%); /* Agrandissement au survol */

                @media screen and (max-width: 991px) {
                    transform: none; /* Annulation de la transformation pour les tablettes */
                }
            }

            span[aria-hidden='true'] {
                z-index: 2; /* Assurez-vous que l'icône a un z-index supérieur */
            }

            &::before,
            &::after {
                content: ''; /* Contenu vide pour les pseudo-éléments */
                position: absolute; /* Position absolue par rapport au parent */
                top: 0; /* Position en haut */
                width: 11%; /* Largeur */
                height: 90%; /* Hauteur */
                background: #fff; /* Couleur de fond */
                border-radius: 8px; /* Coins arrondis */
                transition: 0.25s; /* Transition fluide */
                filter: blur(30px); /* Filtre de flou */
                z-index: 1; /* Assurez-vous que les pseudo-éléments ont un z-index inférieur */

                @media screen and (max-width: 767px) {
                    width: 40%; /* Ajustement de la largeur pour les mobiles */
                    height: 100%; /* Hauteur ajustée pour les mobiles */
                }
            }

            &:hover {
                &::before,
                &::after {
                    transform: scale(110%); /* Agrandissement au survol */

                    @media screen and (max-width: 991px) {
                        transform: none; /* Annulation de la transformation pour les tablettes */
                    }
                }
            }

            &:nth-child(1) {
                &::before,
                &::after {
                    background: linear-gradient(
                        215deg,
                        $react-color-darker,
                        $react-color
                    ); /* Dégradé linéaire */
                }
            }
        }
    }

    .skills__icon {
        /* Style pour les icônes de compétence */
        font-size: 4rem; /* Taille de police */
        height: 4rem; /* Hauteur */
        width: auto; /* Largeur automatique */
        margin-bottom: 0.5rem; /* Marge inférieure */
        z-index: 10; /* Position z */

        @media screen and (max-width: 767px) {
            font-size: 3rem; /* Taille réduite de police pour les mobiles */
            height: 3rem; /* Hauteur réduite pour les mobiles */
        }
    }

    .wave-icons {
        /* Style pour les icônes de compétence */
        height: 4rem; /* Hauteur */
        width: auto; /* Largeur automatique */
        margin-bottom: 0.5rem; /* Marge inférieure */
        z-index: 10; /* Position z */

        @media screen and (max-width: 767px) {
            height: 3rem; /* Hauteur réduite pour les mobiles */
        }
    }

    figcaption {
        font-size: 1rem; /* Taille de police pour la légende */
    }
}

.my-md-5 {
    /* Style spécifique pour la classe 'my-md-5' */
    margin-top: 0.5rem !important; /* Marge supérieure avec importance élevée */
    margin-bottom: 0.5rem !important; /* Marge inférieure avec importance élevée */
}

.otherTitle {
    padding-top: 1.5em;
}