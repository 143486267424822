@import './mixins'; // Import des mixins définis précédemment
@import './variables'; // Import des variables définies précédemment

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    font-family: 'Raleway'; // Utilisation de la police Raleway pour tout le contenu
}

section {
    padding: 4.5rem 0; // Espacement par défaut pour toutes les sections
}

.max-width {
    max-width: 1300px; // Largeur maximale pour les contenus centrés
    padding: 0 80px; // Espacement horizontal par défaut
    margin: auto; // Centrage automatique

    // MEDIA QUERIES - TABLETTES
    @media screen and (max-width: 991px) {
        padding: 0 40px; // Réduction de l'espacement horizontal pour les tablettes
    }
    // MEDIA QUERIES - MOBILES
    @media screen and (max-width: 767px) {
        padding: 0 25px; // Réduction de l'espacement horizontal pour les mobiles
    }
}

section .title {
    position: relative;
    text-align: center;
    font-family: 'Permanent Marker'; // Utilisation de la police Permanent Marker pour les titres
    font-size: 40px;
    font-weight: 500;
    margin-bottom: 60px; // Marge en bas des titres
    padding-bottom: 20px; // Espacement en bas des titres
    color: $titles-color-dark; // Utilisation de la couleur de titre sombre définie dans les variables

    // MEDIA QUERIES - TABLETTES
    @media screen and (max-width: 991px) {
        margin-bottom: 1rem; // Réduction de la marge en bas pour les tablettes
    }
    // MEDIA QUERIES - MOBILES
    @media screen and (max-width: 767px) {
        margin-bottom: 1rem; // Réduction de la marge en bas pour les mobiles
    }

    &::after {
        content: '';
        display: block;
        height: 4px; /* Ajustement de la hauteur selon le besoin */
        width: 10%; /* Ajustement de la largeur en fonction du titre */
        background: linear-gradient(
            to right,
            $react-color,
            $react-color-darker
        ); // Dégradé en arrière-plan
        position: absolute;
        bottom: 15px;
        left: 50%;
        transform: translateX(-50%); // Centrage de la ligne sous le titre
    }
}
// Animation des boîtes au survol
.box {
    transition: transform 0.3s ease;

    &:hover {
        transform: translateY(-10px); // Translation vers le haut au survol
    }
}
// Animation des boutons au survol
.button {
    transition: transform 0.3s ease;

    &:hover {
        transform: scale(1.1); // Agrandissement au survol
    }
}
// Animation des images au survol
.image {
    transition: filter 0.3s ease;

    &:hover {
        filter: brightness(0.8); // Réduction de la luminosité au survol
    }
}
