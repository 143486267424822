@import '../../styles/variables';
@import '../../styles/mixins';

// Styles pour la section services
.services {
    color: #fff; /* Couleur du texte */
    background: $quartary-color; /* Couleur de fond */
    position: relative; /* Position relative pour les éléments positionnés */
    border-bottom: 3px solid $react-color-darker; /* Bordure inférieure */
}

.container {
    background: $quartary-color; /* Couleur de fond */
    display: flex; /* Utilisation de flexbox */
    justify-content: center; /* Centrage horizontal */
    align-items: center; /* Centrage vertical */
    flex-wrap: wrap; /* Permettre le retour à la ligne */

    // MEDIA QUERIES - TABLETTES
    @media screen and (max-width: 991px) {
        padding: 0; /* Ajustement du padding pour les tablettes */
    }
    // MEDIA QUERIES - MOBILES
    @media screen and (max-width: 767px) {
        padding: 0; /* Ajustement du padding pour les mobiles */
    }

    .box {
        position: relative; /* Position relative pour les effets positionnés */
        width: 400px; /* Largeur fixe */
        height: 140px; /* Hauteur fixe */
        display: flex; /* Utilisation de flexbox */
        justify-content: center; /* Centrage horizontal */
        align-items: center; /* Centrage vertical */
        margin: 40px 40px 80px 40px; /* Marges extérieures */
        transition: 0.5s; /* Transition générale */

        // MEDIA QUERIES - TABLETTES
        @media screen and (max-width: 991px) {
            width: 45%; /* Ajustement de la largeur pour deux boîtes par ligne */
            margin: 36px 2.5%; /* Espacement entre les boîtes */
        }
        // MEDIA QUERIES - MOBILES
        @media screen and (max-width: 767px) {
            width: 325px; /* Largeur fixe pour les mobiles */
            margin: 40px 40px 1em 40px !important; /* Ajustement de la marge pour les mobiles */
        }
        // Pseudo-éléments avant et après pour les effets visuels
        &::before,
        &::after {
            content: ''; /* Contenu vide pour les pseudo-éléments */
            position: absolute; /* Position absolue par rapport au parent */
            top: 0; /* Position en haut */
            left: 115px; /* Position à gauche */
            width: 50%; /* Largeur */
            height: 100%; /* Hauteur */
            border-radius: 8px; /* Coins arrondis */
            transition: 0.5s; /* Transition fluide */
            transform: skewX(15deg); /* Inclinaison */

            // MEDIA QUERIES - TABLETTES
            @media screen and (max-width: 991px) {
                left: initial; /* Réinitialisation de la position à gauche */
                height: 106%; /* Hauteur ajustée */
                top: -5%; /* Position ajustée */
            }
            // MEDIA QUERIES - MOBILES
            @media screen and (max-width: 767px) {
                left: initial; /* Réinitialisation de la position à gauche */
                height: 104%; /* Hauteur ajustée */
            }
        }
        // Filtre de flou pour le pseudo-élément après
        &::after {
            filter: blur(30px); /* Effet de flou */
        }
        // Effets de survol
        &:hover {
            &::before,
            &::after {
                transform: skewX(0deg); /* Annulation de l'inclinaison */
                left: 20px; /* Décalage à gauche */
                width: calc(100% - 90px); /* Largeur calculée */
            }

            .content {
                left: -10px; /* Décalage à gauche */
                padding: 30px 40px;
                font-size: 1em; /* Taille de police ajustée */
            }
        }
        // MEDIA QUERIES pour désactiver le hover sur tablettes et mobiles
        @media screen and (max-width: 991px) {
            &:hover {
                &::before,
                &::after {
                    transform: none; /* Annulation de la transformation */
                    left: initial; /* Réinitialisation de la position à gauche */
                    width: 50%; /* Largeur fixe */
                }

                .content {
                    left: 0; /* Pas de décalage */
                    padding: 20px; /* Padding réduit */
                    font-size: 2em; /* Taille de police ajustée */
                }
            }
        }
        // Fond dégradé pour la boîte
        &.gradient-background {
            &::before,
            &::after {
                background: linear-gradient(
                    215deg,
                    $react-color-darker,
                    $react-color
                ); /* Dégradé linéaire */
            }
        }

        .content {
            display: flex; /* Utilisation de flexbox */
            flex-direction: column; /* Affichage en colonne */
            align-items: center; /* Centrage horizontal */
            text-align: center; /* Centrage du texte */
            position: relative; /* Position relative pour les enfants positionnés */
            left: 0; /* Position à gauche */
            padding: 20px 40px;
            background: rgba(255, 255, 255, 0.05); /* Fond semi-transparent */
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); /* Ombre légère */
            border-radius: 15px; /* Coins arrondis */
            backdrop-filter: blur(10px); /* Filtre de fond */
            z-index: 1; /* Position z */
            transition: 0.5s; /* Transition fluide */
            color: #fff; /* Couleur du texte */

            &:hover {
                left: -25px; /* Décalage à gauche */
                padding: 60px 40px; /* Expansion du cadre */
                font-size: 1.15em; /* Taille de police ajustée */
            }

            // MEDIA QUERIES - TABLETTES
            @media screen and (max-width: 991px) {
                &:hover {
                    left: 0; /* Pas de décalage */
                    padding: 30px;
                    font-size: 1.2em; /* Taille de police ajustée */
                }
            }
            // Titre du service
            h2 {
                font-size: 1.8em; /* Taille de police */
                margin-bottom: 0px; /* Marge inférieure */

                // MEDIA QUERIES - TABLETTES
                @media screen and (max-width: 991px) {
                    font-size: 1.6em; /* Taille de police ajustée */
                    margin-bottom: 0; /* Pas de marge inférieure */
                }
            }
            // Description du service
            p {
                font-size: 1.1em; /* Taille de police */
                margin-bottom: 10px; /* Marge inférieure */
                line-height: 1.4em; /* Hauteur de ligne */
                padding: 12px 0px 12px 10px;

                // MEDIA QUERIES - TABLETTES
                @media screen and (max-width: 991px) {
                    font-size: 1rem; /* Taille de police ajustée */
                }
                // MEDIA QUERIES - MOBILES
                @media screen and (max-width: 767px) {
                    font-size: 0.8rem; /* Taille de police ajustée */
                }
                // Effet de survol pour mobiles et tablettes
                @media screen and (max-width: 991px) {
                    &:hover {
                        font-size: 1.15rem; /* Taille de police ajustée au survol */
                        transition: font-size 0.1s ease-in-out; /* Transition fluide */
                    }
                }

                @media screen and (max-width: 767px) {
                    &:hover {
                        font-size: 0.88rem; /* Taille de police ajustée au survol */
                        transition: font-size 0.1s ease-in-out; /* Transition fluide */
                    }
                }
            }
            // Lien vers plus d'informations
            a {
                display: inline-block; /* Affichage en bloc en ligne */
                font-size: 1.1em; /* Taille de police */
                color: #111; /* Couleur du texte */
                background: #fff; /* Couleur de fond */
                padding: 10px;
                border-radius: 4px; /* Coins arrondis */
                text-decoration: none; /* Pas de soulignement */
                font-weight: 700; /* Poids de la police */
                margin-top: 5px; /* Marge supérieure */
            }
        }
        // Icône animée
        span {
            display: block; /* Affichage en bloc */
            position: absolute; /* Position absolue par rapport au parent */
            top: 145px; /* Position basse */
            left: 35%; /* Position à droite */
            width: 70px; /* Largeur */
            height: 70px; /* Hauteur */
            background-size: 70px; /* Taille du fond */
            background-position: center; /* Position du fond */
            background-repeat: no-repeat; /* Répétition du fond */
            z-index: 2; /* Position z */
            opacity: 1; /* Opacité */
            animation: move-up-down 2s ease-in-out infinite; /* Animation de mouvement */
            pointer-events: none; /* Événements de pointage non activés */

            // MEDIA QUERIES - TABLETTES
            @media screen and (max-width: 991px) {
                top: 125px; /* Position basse */
                left: 40%; /* Position à droite */
            }
            // MEDIA QUERIES - MOBILES
            @media screen and (max-width: 767px) {
                top: 125px; /* Position basse */
            }
            &.animate {
                animation: move-up-down 2s ease-in-out infinite; /* Animation de mouvement */
            }
        }
    }
}
// Animation keyframes
@keyframes animate {
    0%,
    100% {
        transform: translateY(10px); /* Translation verticale */
    }
    50% {
        transform: translateY(-10px); /* Translation verticale */
    }
}

@keyframes move-up-down {
    0%,
    100% {
        transform: translateY(0); /* Translation verticale */
    }
    50% {
        transform: translateY(-10px); /* Translation verticale */
    }
}
