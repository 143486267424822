// Import des variables et mixins globaux
@import './styles/variables';
@import './styles/mixins';
// Import des composants spécifiques
@import './components/Carousel/Carousel';
@import './components/Navbar/Navbar';
@import './components/ScrollUpButton/ScrollUpButton';

// Styles globaux pour le corps du document
body {
    font-family: $font-stack; // Utilisation de la pile de polices définie dans les variables
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
// Styles pour les balises <code> et les éléments de code
code {
    font-family: $code-font-stack; // Utilisation de la pile de polices pour le code définie dans les variables
}
