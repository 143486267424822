// Import des variables et mixins
@import '../../styles/variables';
@import '../../styles/mixins';

// Styles pour le bouton de scroll vers le bas
.scroll-down-btn {
    position: fixed; /* Position fixe par rapport à la fenêtre */
    bottom: 20px; /* Distance depuis le bas de la fenêtre */
    left: 50%; /* Centrage horizontal */
    transform: translateX(-50%); /* Ajustement pour centrer horizontalement */
    background-color: $quinary-color; /* Couleur de fond du bouton */
    color: $react-color; /* Couleur du texte du bouton */
    border: 2px solid $react-color; /* Bordure solide autour du bouton */
    border-radius: 50%; /* Bordure arrondie pour créer un cercle */
    width: 50px; /* Largeur du bouton */
    height: 50px; /* Hauteur du bouton */
    text-align: center; /* Centrage du contenu textuel */
    display: none; /* Masquer le bouton par défaut */
    cursor: pointer; /* Curseur pointeur au survol */
    z-index: 1000; /* Empilement au-dessus des autres éléments */
    // Texte à l'intérieur du bouton
    .scroll-down-text {
        font-family: 'Permanent Marker'; /* Police de caractères du texte */
        font-size: 2em; /* Taille de la police du texte */
        font-weight: bolder; /* Graisse de la police du texte */
        position: absolute; /* Position absolue par rapport au bouton */
        top: -40px; /* Décalage vers le haut pour positionner le texte */
        left: 50%; /* Centrage horizontal du texte */
        transform: translateX(
            -50%
        ); /* Ajustement pour centrer horizontalement */
        white-space: nowrap; /* Empêche le texte de se couper en plusieurs lignes */
        color: $quinary-color; /* Couleur du texte pour contraster avec le contour */
        text-shadow:
            -1px -1px 0 $react-color,
            1px -1px 0 $react-color,
            -1px 1px 0 $react-color,
            1px 1px 0 $react-color; /* Contour textuel avec ombres multiples */
    }
    // Classe ajoutée pour afficher le bouton
    &.show {
        display: block; /* Afficher le bouton */
        opacity: 1; /* Opacité maximale */
        pointer-events: auto; /* Activer les événements de pointeur */
        transition:
            opacity 0.3s ease,
            bottom 0.3s ease; /* Transition fluide */
    }
    // Effet de luminosité au survol du bouton
    &:hover {
        filter: brightness(90%); /* Réduire légèrement la luminosité */
    }
    // Icône à l'intérieur du bouton
    i {
        line-height: 50px; /* Centrage vertical de l'icône */
    }
}
