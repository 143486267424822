// Import des variables et mixins
@import '../../styles/variables';
@import '../../styles/mixins';

// Styles globaux pour la modal
.modal {
    display: flex; // Centre la modal verticalement et horizontalement
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 9999; // Assure que la modal est au-dessus des autres éléments
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto; // Permet de faire défiler le contenu si nécessaire
    background-color: rgba(0, 0, 0, 0.2); // Réduit l'opacité du fond sombre
    // Styles pour le contenu de la modal
    .modal-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $tertiary-color; // Utilise la couleur définie dans les variables
        margin: 10vh auto; // Centre verticalement avec une marge supérieure
        padding: 20px; // Ajoute un espacement interne
        width: 85%; // Définit une largeur par défaut
        max-width: 800px; // Limite la largeur maximale
        max-height: 85vh; // Limite la hauteur maximale
        overflow: auto; // Ajoute un défilement si le contenu dépasse
        border-radius: 10px; // Arrondit les coins
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.3); // Ajoute une ombre portée légère

        @media (max-width: 768px) {
            width: 95%; // Ajuste la largeur sur les petits écrans
            max-width: 95%; // Limite la largeur maximale sur les petits écrans
            max-height: 90vh; // Augmente la hauteur maximale sur les petits écrans
        }
        // Styles pour le contenu personnalisé de la modal
        &-custom {
            img {
                max-width: 100%; // Ajuste l'image à la largeur du conteneur
                border-radius: 10px; // Arrondit les coins de l'image
                max-height: 70vh; // Limite la hauteur maximale de l'image
                object-fit: contain; // Ajuste l'image tout en conservant les proportions
                margin: 0; // Supprime les marges
            }
        }
    }
    // Styles pour les liens de la modal
    .modal-links {
        display: flex;
        justify-content: center; // Centre les liens horizontalement
        margin-top: 20px; // Ajoute un espacement supérieur

        a {
            display: inline-block;
            margin: 0 10px; // Ajoute un espacement horizontal entre les liens
            color: #fff; // Couleur du texte des liens
            text-decoration: none;
            padding: 10px 20px; // Ajoute un espacement interne aux liens
            border-radius: 5px; // Arrondit les coins des liens
            transition:
                background-color 0.3s,
                color 0.3s; // Ajoute des transitions pour les changements de couleur

            &:hover {
                color: $react-color; // Change la couleur du texte au survol
            }

            svg {
                margin-right: 5px; // Ajoute un espacement entre l'icône et le texte
            }
        }
    }
    // Styles pour l'en-tête et le pied de page de la modal
    .modal-header,
    .modal-footer {
        display: flex;
        justify-content: center !important; // Centre le contenu horizontalement
    }
    // Styles pour le bouton de fermeture de la modal
    .close {
        color: #aaa; // Couleur par défaut du bouton de fermeture
        font-size: 28px;
        font-weight: bold;

        &:hover,
        &:focus {
            color: black; // Change la couleur au survol et au focus
            text-decoration: none;
            cursor: pointer;
        }
    }
    // Styles pour le titre de la modal
    .modal-title {
        margin-bottom: 1em; // Ajoute un espacement inférieur
        font-size: larger; // Augmente la taille de la police
        font-weight: bold;
    }
    // Styles pour le bouton dans la modal
    button {
        background-color: $tertiary-color; // Utilise la couleur définie dans les variables
        color: $secondary-color; // Utilise la couleur définie dans les variables
        width: auto; // Ajuste automatiquement la largeur
        padding: 12px 24px; // Ajoute un espacement interne au bouton
        margin-top: 15px; // Ajoute un espacement supérieur
        font-size: 18px;
        font-weight: 500;
        border-radius: 6px; // Arrondit les coins du bouton
        cursor: pointer;
        border: 2px solid $react-color; // Ajoute une bordure colorée
        transition:
            background 0.3s ease-in-out,
            color 0.3s ease-in-out; // Ajoute des transitions pour les changements de couleur

        &:hover {
            background: $react-color; // Change la couleur de fond au survol
            color: $tertiary-color; // Change la couleur du texte au survol
        }
    }
    // Styles pour les paragraphes dans la modal
    p {
        margin-top: 1em; // Ajoute un espacement supérieur
    }
}
