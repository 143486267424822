// Import des variables et mixins
@import '../../styles/variables';
@import '../../styles/mixins';

// Styles pour le bouton de scroll vers le haut
.scroll-up-btn {
    position: fixed; /* Position fixe par rapport à la fenêtre */
    bottom: 20px; /* Distance depuis le bas de la fenêtre */
    right: 30px; /* Distance depuis la droite de la fenêtre */
    background-color: $quinary-color; /* Couleur de fond du bouton */
    color: $react-color; /* Couleur du texte du bouton */
    border: 2px solid $react-color; /* Bordure solide autour du bouton */
    border-radius: 50%; /* Bordure arrondie pour créer un cercle */
    width: 50px; /* Largeur du bouton */
    height: 50px; /* Hauteur du bouton */
    text-align: center; /* Centrage du contenu textuel */
    display: none; /* Masquer le bouton par défaut */
    cursor: pointer; /* Curseur pointeur au survol */
    z-index: 1000; /* Empilement au-dessus des autres éléments */
    // Classe ajoutée pour afficher le bouton
    &.show {
        display: block; /* Afficher le bouton */
        opacity: 1; /* Opacité maximale */
        pointer-events: auto; /* Activer les événements de pointeur */
        bottom: 30px; /* Ajustement de la position depuis le bas */
        transition: opacity 0.3s ease, bottom 0.3s ease; /* Transition fluide */
    }
    // Effet de luminosité au survol du bouton
    &:hover {
        filter: brightness(90%); /* Réduire légèrement la luminosité */
    }
    // Icône à l'intérieur du bouton
    i {
        line-height: 30px; /* Centrage vertical de l'icône */
    }
}
