// Import des variables et mixins
@import '../../styles/variables';
@import '../../styles/mixins';

// Styles pour le conteneur du carrousel
.carousel-container {
    position: relative; /* Position relative pour les éléments enfants positionnés absolument */
    margin: 0 20px 0 0; /* Ajuste les marges pour décaler le carrousel */
    // Styles pour chaque élément du carrousel
    .item {
        position: relative; /* Position relative pour positionner les pseudo-éléments */
        padding: 10px; /* padding des éléments du carrousel */
        margin: 10px; // Ajoute des marges pour espacer les éléments
        border-radius: 10px; /* Coins arrondis pour les éléments */
        text-align: center; /* Centre le contenu à l'intérieur des éléments */
        overflow: hidden; /* Assure que les éléments ne débordent pas */
        transition: all 0.3s ease; /* Transition fluide pour les effets de survol */
        // Effets au survol des éléments du carrousel
        &:hover {
            cursor: pointer; /* Curseur pointeur au survol */
            box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.25); /* Ombre légère pour effet de profondeur */
            border: 2px solid $react-color; /* Bordure avec la couleur React définie */

            &::before {
                opacity: 1; /* Rend le voile visible au survol */
            }
        }
        // Pseudo-élément pour le voile au survol
        &::before {
            content: ''; /* Contenu vide requis pour pseudo-élément */
            position: absolute; /* Position absolue pour couvrir l'élément */
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $react-color-darker; /* Couleur du voile semi-transparent */
            opacity: 0; /* Masque le voile par défaut */
            transition: opacity 0.3s ease; /* Transition fluide pour l'apparition du voile */
            border-radius: 10px; /* Coins arrondis pour correspondre aux éléments */
            z-index: 2; /* Positionne le voile au-dessus du contenu */
        }
        // Contenu des éléments du carrousel
        .content {
            position: relative; /* Position relative pour le contenu */
            z-index: 3; /* Place le contenu au-dessus du pseudo-élément */
        }
        // Liens dans les éléments du carrousel
        a {
            display: block; /* Affichage en bloc pour occuper toute la largeur */
            text-decoration: none; /* Pas de soulignement pour les liens */
            color: inherit; /* Couleur héritée du texte */
            // Images dans les liens
            img {
                max-width: 100%; /* Image occupe toute la largeur de son conteneur */
                border-radius: 10px; /* Coins arrondis pour les images */
                margin-bottom: 10px; /* Marge inférieure pour espacement */
            }
            // Informations sur le projet dans les liens
            .project-info {
                h3 {
                    font-size: 1.5em; /* Taille de police du titre */
                    margin: 10px 0; /* Marges autour du titre */
                }

                p {
                    font-size: 1em; /* Taille de police du paragraphe */
                }
            }
        }
    }
}

// Styles pour le carrousel principal
.carousel {
    .slick-list {
        padding: 0 20px; /* Ajuster pour centrer les éléments */
    }

    .slick-slide {
        display: flex; /* Affichage flex pour les diapositives */
        justify-content: center; /* Centrer horizontalement */
        align-items: center; /* Centrer verticalement */
    }

    .slick-track {
        display: flex; /* Affichage flex pour la piste */
        align-items: center; /* Centrer verticalement */
    }

    // MEDIA QUERIES - TABLETTES
    @media screen and (max-width: 991px) {
        .carousel {
            width: 100%; /* Largeur maximale pour les tablettes */
            margin-left: 0; /* Ajuster la marge gauche pour centrer le carrousel */
            padding: 0; /* Pas de padding horizontal pour centrer */
            overflow: hidden; /* Assure que les éléments ne débordent pas */
        }
    }
    // MEDIA QUERIES - MOBILES
    @media screen and (max-width: 767px) {
        .carousel {
            width: 100%; /* Largeur maximale pour les mobiles */
            padding: 0; /* Pas de padding horizontal pour centrer */
            margin: 0; /* Pas de marges pour centrer */
            overflow: hidden; /* Assure que les éléments ne débordent pas */
        }
    }
}
