@import '../../styles/variables';
@import '../../styles/mixins';

// Styles généraux pour la page d'accueil
.home {
    display: flex;
    align-items: center;
    justify-content: center;
    background: url('../../images/background-dark.jpg') no-repeat center;
    background-size: cover;
    min-height: 100vh; /* Utilisation de 100vh pour prendre toute la hauteur de l'écran */
    position: relative; /* positionnée relativement pour que l'animation fonctionne correctement */
    border-bottom: 3px solid $react-color-darker; /* bordure inférieure */

    // MEDIA QUERIES - TABLETTES
    @media screen and (max-width: 991px) {
        min-height: 99vh; /* Réduire la hauteur sur les tablettes */
    }
    // MEDIA QUERIES - MOBILES
    @media screen and (max-width: 767px) {
        min-height: 99vh; /* Réduire encore la hauteur sur les mobiles */
    }
    // Point décoratif en dessous de la section
    &::before,
    &::after {
        content: '';
        position: absolute;
        bottom: -3px; /* Positionner en bas */
        height: 3px; /* Taille de la ligne */
        background-color: $react-color; /* Couleur du point et de la ligne */
        border-radius: 50%; /* Rendre le point circulaire */
    }
    // Animation du point et de la ligne à gauche
    &::before {
        width: 10px;
        @include move-dot-left; /* Mixin pour déplacer le point */
        @include draw-line-left; /* Mixin pour dessiner la ligne */
    }
    // Animation du point et de la ligne à droite
    &::after {
        width: 10px;
        @include move-dot-right; /* Mixin pour déplacer le point */
        @include draw-line-right; /* Mixin pour dessiner la ligne */
    }
    // Contenu principal de la page d'accueil
    .home-content {
        text-align: center;
        color: $home-color-dark; /* Utilisation de la variable de couleur */
        display: flex;
        flex-direction: column;
        margin-top: -100px; /* Ajuster cette valeur selon vos besoins */
    }
}
