// Import des variables et mixins
@import '../../styles/mixins';
@import '../../styles/variables';

// Styles pour le texte 1
.text-1 {
    font-size: 40px; /* Taille de police */
    font-weight: 600; /* Graisse du texte */
    font-family: 'Permanent Marker'; /* Police de caractères */

    // MEDIA QUERIES - TABLETTES
    @media screen and (max-width: 991px) {
        font-size: 30px; /* Réduire la taille de police pour les tablettes */
    }
    // MEDIA QUERIES - MOBILES
    @media screen and (max-width: 767px) {
        font-size: 24px; /* Réduire encore la taille de police pour les mobiles */
    }
}
// Styles pour le texte 2
.text-2 {
    font-size: 75px; /* Taille de police */
    font-weight: 600; /* Graisse du texte */
    margin: 10px 0; /* Marges supérieure et inférieure */
    font-family: 'Permanent Marker'; /* Police de caractères */

    // MEDIA QUERIES - TABLETTES
    @media screen and (max-width: 991px) {
        font-size: 55px; /* Réduire la taille de police pour les tablettes */
    }
    // MEDIA QUERIES - MOBILES
    @media screen and (max-width: 767px) {
        font-size: 40px; /* Réduire encore la taille de police pour les mobiles */
    }
}
// Styles pour le texte 3
.text-3 {
    font-size: 40px; /* Taille de police */
    font-weight: 600; /* Graisse du texte */
    font-family: 'Permanent Marker'; /* Police de caractères */

    span {
        font-family: 'Permanent Marker'; /* Police de caractères pour les spans */
        color: $react-color; /* Utilisation de la variable pour la couleur */
    }

    // MEDIA QUERIES - TABLETTES
    @media screen and (max-width: 991px) {
        font-size: 30px; /* Réduire la taille de police pour les tablettes */
    }
    // MEDIA QUERIES - MOBILES
    @media screen and (max-width: 767px) {
        font-size: 24px; /* Réduire encore la taille de police pour les mobiles */
    }
}
