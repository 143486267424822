@import '../../styles/variables';
@import '../../styles/mixins';

// Styles généraux pour la section Projects
.projects {
    background-color: $tertiary-color; /* Couleur de fond */
    position: relative; /* Position relative pour l'animation */
    border-bottom: 3px solid $react-color-darker; /* Bordure inférieure */
    min-height: 80vh; /* Hauteur minimale */
    overflow: auto; /* Activation du défilement vertical si nécessaire */

    .title {
        font-size: 2.5rem; /* Taille de police */
        margin-bottom: 1rem; /* Marge inférieure */
        text-align: center; /* Centrage horizontal */
    }

    .projects-content-inner {
        display: grid;
        grid-template-columns: repeat(
            auto-fit,
            minmax(300px, 1fr)
        ); /* Grille flexible avec 1 colonne minimum de 300px */
        gap: 2rem; /* Espacement entre les éléments */
    }

    .project-item {
        background: $secondary-color; /* Couleur de fond des items */
        padding: 6px;
        border: 1px solid $secondary-color; /* Bordure de la même couleur que le fond */
        transition: 0.3s ease; /* Transition fluide */
        position: relative; /* Position relative pour le contenu absolu */
        overflow: hidden; /* Masquage du contenu dépassant */
        border-radius: 10px; /* Coins arrondis */
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1); /* Ombre légère */

        img {
            max-width: 100%; /* Image avec largeur maximale de 100% */
            display: block; /* Affichage en bloc */
            border-radius: 10px; /* Coins arrondis */
        }

        &:hover {
            box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.25); /* Ombre plus prononcée au survol */
            border: 2px solid $react-color; /* Bordure épaisse de couleur principale au survol */
        }
    }

    .project-content {
        position: absolute; /* Position absolue par rapport au parent */
        top: 0;
        left: 0;
        height: 100%; /* Hauteur maximale */
        width: 100%; /* Largeur maximale */
        background: rgba(0, 0, 0, 0.6); /* Fond semi-transparent */
        display: flex;
        justify-content: center; /* Centrage horizontal */
        align-items: center; /* Centrage vertical */
        flex-direction: column; /* Colonne pour le contenu */
        opacity: 0; /* Opacité initiale à zéro */
        transition: opacity 0.3s ease; /* Transition d'opacité fluide */
        padding: 20px;
        box-sizing: border-box; /* Boîte de dimension incluant les bordures */
        border-radius: 10px; /* Coins arrondis */

        p {
            margin-bottom: 10px; /* Marge inférieure */
            color: #fff; /* Couleur du texte */
            text-align: center; /* Centrage horizontal */
        }

        .project-links {
            display: flex; /* Utilisation de flexbox */
            justify-content: center; /* Centrage horizontal */

            a {
                display: inline-block; /* Affichage en ligne avec bloc */
                margin: 0 10px; /* Marge autour des liens */
                color: #fff; /* Couleur du texte */
                text-decoration: none; /* Pas de soulignement */
                transition: color 0.3s ease; /* Transition de couleur fluide */

                &:hover {
                    color: $react-color; /* Couleur principale au survol */
                }

                svg {
                    margin-right: 5px; /* Marge à droite pour les icônes */
                }
            }
        }
    }

    .project-item:hover .project-content {
        opacity: 1; /* Afficher le contenu au survol */
    }
}
