// Import des variables et mixins
@import '../../styles/variables';
@import '../../styles/mixins';

// Styles pour les icônes sociales
.stick_follow_icon {
    font-size: 2rem; /* Taille de police pour les icônes */
    width: 100%; /* Largeur maximale */
    height: auto; /* Hauteur automatique */
    display: flex; /* Affichage en mode flexbox */
    align-items: center; /* Centrage vertical des éléments enfants */
    justify-content: center; /* Centrage horizontal des éléments enfants */
    flex-direction: column; /* Disposition en colonne des éléments enfants */
    position: relative; /* Position relative pour le positionnement des éléments enfants */
    padding-top: 1em; /* Espacement en haut */
    padding-bottom: 0; /* Pas d'espacement en bas */

    ul {
        list-style: none; /* Suppression des puces de liste */
        padding: 0; /* Pas de padding */
        margin: 0; /* Pas de marges extérieures */

        li {
            display: inline-block; /* Affichage en ligne des éléments */
            margin: 0 1em 0 1em; /* Marges autour des éléments */
            transition: all 0.3s; /* Transition fluide pour les animations */

            &:hover {
                transform: scale(1.2); /* Grossissement de 20% au survol */
            }

            a {
                display: block; /* Affichage en bloc pour occuper tout l'espace disponible */
                text-align: center; /* Centrage du texte */
    
                svg {
                    width: 1.3em; /* Largeur de l'icône */
                    height: 1.3em; /* Hauteur de l'icône */
                    fill: $body-color-dark; /* Couleur de remplissage de l'icône */
                }
            }
        }
    }

    p {
        font-family: 'Permanent Marker'; /* Police de caractères pour le texte */
        font-size: 2rem; /* Taille de police */
        font-weight: 600; /* Graisse du texte */
        line-height: 1.2; /* Hauteur de ligne */
        white-space: nowrap; /* Empêche le texte de se retourner sur plusieurs lignes */
        margin-top: 0px; /* Marge supérieure nulle */
        margin-bottom: 0; /* Marge inférieure nulle */
    }
}
