@import '../../styles/variables';
@import '../../styles/mixins';

// Styles pour la barre de navigation
.navbar {
    background-color: $quinary-color; /* Couleur de fond de la barre de navigation */
    position: fixed; /* Barre de navigation positionnée fixement en haut */
    width: 100%; /* Largeur de la barre de navigation */
    height: 6vh; /* Hauteur de la barre de navigation */
    min-height: 6vh; /* Hauteur min de la barre de navigation */
    z-index: 999; /* Empilement au-dessus des autres éléments */
    padding: 10px 0; /* Padding en haut et en bas */
    transition: all 0.3s ease; /* Animation fluide des transitions */
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1); /* Ombre légère sous la barre de navigation */
    border-bottom: 2px solid $react-color; /* Bordure en bas de la barre de navigation */
    // Logo de la barre de navigation
    .navbar-logo {
        display: flex; /* Affichage en flexbox pour le logo */
        font-family: 'Permanent Marker'; /* Police de caractères pour le logo */
        font-size: x-large; /* Taille de la police du logo */
        color: $navsticky-color-dark; /* Couleur du texte du logo */
        margin-bottom: 0; /* Marge en bas du logo */
        position: absolute; /* Position absolue pour le logo */
        top: 50%; /* Alignement vertical au milieu */
        left: 5vw; /* Décalage à gauche */
        transform: translateY(-50%); /* Ajustement pour centrer verticalement */
        z-index: 999; /* Empilement au-dessus des autres éléments */
        &:hover {
            filter: brightness(
                90%
            ); /* Luminosité légèrement réduite au survol */
            scale: 0.97; /* Légère réduction de l'échelle au survol */
        }
    }
    // Conteneur de largeur maximale pour la barre de navigation
    .max-width {
        display: flex; /* Affichage en flexbox */
        align-items: center; /* Centrage vertical des éléments enfants */
        justify-content: space-between; /* Espace équitable entre les éléments enfants */

        // MEDIA QUERIES - MOBILES
        @media screen and (max-width: 767px) {
            align-items: center; /* Centrage vertical des enfants pour les mobiles */
        }
    }
    // Icône de menu hamburger
    .menu-icon {
        display: block; /* Affichage en bloc pour l'icône de menu */
        margin-right: 20px; /* Marge à droite de l'icône de menu */
        cursor: pointer; /* Curseur pointeur pour l'icône de menu */
        position: absolute; /* Position absolue pour l'icône de menu */
        right: 5vw; /* Décalage à droite */

        &:hover {
            filter: brightness(
                90%
            ); /* Luminosité légèrement réduite au survol */
            scale: 0.97; /* Légère réduction de l'échelle au survol */
        }

        // Barres de l'icône de menu
        .bar1,
        .bar2,
        .bar3 {
            width: 25px; /* Largeur des barres */
            height: 3px; /* Hauteur des barres */
            background-color: $navsticky-color-dark; /* Couleur des barres */
            margin: 4px 0; /* Marge entre les barres */
            transition: 0.4s; /* Transition fluide */
        }
        &.open .bar1 {
            transform: rotate(45deg) translate(5px, 5px) !important; /* Rotation de la première barre en position ouverte */
        }
        &.open .bar2 {
            opacity: 0 !important; /* Disparition de la deuxième barre en position ouverte */
        }
        &.open .bar3 {
            transform: rotate(-45deg) translate(5px, -5px) !important; /* Rotation de la troisième barre en position ouverte */
        }

        // MEDIA QUERIES - ABLETTES
        @media screen and (max-width: 991px) {
            display: block; /* Afficher le bouton hamburger sur les tablettes */
        }
        // MEDIA QUERIES - MOBILES
        @media screen and (max-width: 767px) {
            display: block; /* Afficher le bouton hamburger sur les mobiles */
        }
    }
    // Menu déroulant mobile
    .menu {
        display: none; /* Masquer le menu par défaut */
        flex-direction: column; /* Disposition en colonne pour le menu */
        position: fixed; /* Position fixe pour le menu */
        top: 6vh; /* Décalage vers le bas équivalent à la hauteur de la barre de navigation */
        left: 0; /* Alignement à gauche */
        right: 0; /* Alignement à droite */
        bottom: -1vh; /* Décalage vers le haut */
        list-style: none; /* Suppression des puces de liste */
        align-items: center; /* Centrage des éléments enfants */
        z-index: 199; /* Empilement au-dessus de la barre de navigation */
        padding: 0; /* Aucun padding */
        background-color: $quinary-color; /* Couleur de fond du menu */
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1); /* Ombre légère sous le menu */
        border-bottom: 2px solid $react-color; /* Bordure en bas du menu */

        &.open {
            display: flex; /* Afficher le menu en position ouverte */
            animation: slideDown 0.3s forwards; /* Animation de descente */
        }

        &.close {
            display: flex; /* Afficher le menu en position fermée */
            animation: slideUp 0.3s forwards; /* Animation de montée */
        }
        // Éléments de menu
        li {
            margin: 10px; /* Marge autour des éléments de menu */
            display: inline-block; /* Affichage en ligne pour les éléments de menu */
            width: auto; /* Largeur automatique */
            &:hover {
                transform: scale(1.15);
                transition: all 0.3s ease; /* Transition fluide pour les animations */
            }
            a {
                display: block; /* Affichage en bloc pour les liens de menu */
                color: $navsticky-color-dark; /* Couleur du texte du menu */
                font-size: 2.5em; /* Taille de la police du menu */
                font-weight: 700; /* Poids de la police du menu */
                padding: 10px 20px; /* Padding des liens de menu */
                text-decoration: none; /* Aucune décoration de texte */
                text-align: center; /* Alignement centré du texte des liens */
                transition: all 0.3s ease; /* Transition fluide pour les animations */

                // MEDIA QUERIES - TABLETTES
                @media screen and (max-width: 991px) {
                    font-size: 2.5em; /* Taille de la police pour les tablettes */
                }
                // MEDIA QUERIES - MOBILES
                @media screen and (max-width: 767px) {
                    font-size: 2em; /* Taille de la police pour les mobiles */
                }

                &.active {
                    color: $react-color; /* Couleur pour les liens de menu actifs */
                }
            }

            // MEDIA QUERIES - TABLETTES
            @media screen and (max-width: 991px) {
                margin: 0 5px; /* Réduire légèrement les marges des éléments de menu pour les tablettes */
            }
            // MEDIA QUERIES - MOBILES
            @media screen and (max-width: 767px) {
                margin: 0; /* Réduire encore les marges des éléments de menu pour les mobiles */
            }
        }
    }
    // Bouton de menu
    .menu-btn {
        color: $nav-color-dark; /* Couleur du texte du bouton de menu */
        font-size: 20px; /* Taille de la police du bouton de menu */
        cursor: pointer; /* Curseur pointeur pour le bouton de menu */
    }
}

// Styles supplémentaires pour les listes
dl,
ol,
ul {
    overflow: auto !important; /* Débordement automatique pour les listes */
}
// Animation pour glisser vers le bas
@keyframes slideDown {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0);
    }
}
// Animation pour glisser vers le haut
@keyframes slideUp {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-100%);
    }
}
// Styles pour la barre de navigation en mode ouvert
.navbar.menu-open {
    .menu-icon {
        z-index: 999; /* Empilement au-dessus des autres éléments en mode ouvert */
    }
}
