// Import des variables et mixins
@import '../../styles/variables';
@import '../../styles/mixins';

// Styles pour le footer
footer {
    width: 100%; /* Largeur maximale du footer */
    background: linear-gradient(
        315deg,
        #44caeb,
        #206474
    ); /* Dégradé de couleur de fond */
    min-height: 100px; /* Hauteur minimale du footer */
    padding: 0 50px;
    display: flex; /* Affichage flex pour les éléments enfants */
    align-items: center; /* Centrer verticalement */
    justify-content: center; /* Centrer horizontalement */
    flex-direction: column; /* Disposition en colonne pour le contenu */
    min-height: 20vh; /* Hauteur du footer en vue par rapport à la hauteur de la fenêtre */
    position: relative; // Position relative pour positionner les vagues correctement
    // Styles pour le titre du footer
    h2 {
        margin-bottom: 12px; /* Marge inférieure */
        color: #fff; /* Couleur du texte */
        font-size: 2em; /* Taille de police */
    }
    // Styles pour les médias sociaux dans le footer
    #media {
        display: flex; /* Affichage flex pour les éléments enfants */
        gap: 50px; /* Espacement entre les éléments */
        margin-top: 20px; /* Marge supérieure */

        li {
            list-style: none; /* Pas de puces */
            width: 80px; /* Largeur des icônes */
            height: 80px; /* Hauteur des icônes */
            display: flex; /* Affichage flex pour centrer le contenu */
            justify-content: center; /* Centrer horizontalement */
            align-items: center; /* Centrer verticalement */
            cursor: pointer; /* Curseur pointeur */
            transition:
                transform 0.5s ease,
                z-index 0.5s ease; /* Transition pour les effets de survol */
            position: relative; // Position relative pour les pseudos-éléments

            a {
                text-decoration: none; /* Pas de soulignement pour les liens */
                color: var(
                    --clr
                ); /* Couleur du texte définie par la variable */
                font-size: 2em; /* Taille de police */
                transition: color 0.5s ease; /* Transition pour la couleur du texte */
            }

            &:hover {
                transform: translateY(-10px) scale(0.75); /* Translation et mise à l'échelle au survol */
                z-index: 100000; /* Z-index élevé au survol */

                &::before {
                    inset: 0; // Voile visible au survol
                }
            }

            &::before {
                content: ''; /* Contenu vide requis pour le pseudo-élément */
                position: absolute; /* Position absolue */
                inset: 30px; /* Marge intérieure pour le voile */
                box-shadow:
                    0 0 0 10px var(--clr),
                    0 0 0 20px var(--bg),
                    0 0 0 22px var(--clr); /* Effet de box-shadow */
                transition: 0.5s; /* Transition pour l'effet de box-shadow */
            }

            &::after {
                content: ''; /* Contenu vide requis pour le pseudo-élément */
                position: absolute; /* Position absolue */
                inset: 0; /* Pleine couverture */
                background: var(
                    --bg
                ); /* Couleur d'arrière-plan définie par la variable */
                transform: rotate(45deg); /* Rotation de 45 degrés */
            }
        }
    }
    // Styles pour le texte du footer
    p {
        color: $footer-color; /* Couleur du texte définie par la variable */
        text-align: center; /* Centre le texte horizontalement */
        margin-top: 15px; /* Marge supérieure */
        font-size: 1.1em; /* Taille de police */

        a {
            text-decoration: none; /* Pas de soulignement pour les liens */
            color: $quinary-color; /* Couleur du texte définie par la variable */
            font-size: 1em; /* Taille de police */
            transition: all 0.5s ease-in-out; /* Transition pour tous les effets */

            &:hover {
                text-decoration: underline; /* Soulignement au survol */
                color: $red-color; /* Changement de couleur au survol */
                font-size: 1.2em; /* Changement de taille de police au survol */
                transform: rotateX(10deg); /* Rotation de 10 degrés */
            }
        }
    }
    // Styles pour les icônes de suivi
    .stick_follow_icon {
        ul {
            padding: 0 0 0.5em 0;
        }
        @media (max-width: 768px) {
            padding-top: 0 !important; /* padding supprimé pour les écrans Mobile */
        }

        p {
            @media (max-width: 768px) {
                font-size: 1.5rem !important; /* Taille de police pour les écrans Mobile */
            }
        }

        ul li a svg {
            @media (max-width: 768px) {
                width: 0.85em !important; /* Largeur réduite pour les écrans Mobile */
                height: 1em; /* Hauteur nulle pour les écrans Mobile */
            }
        }
    }
}
